import request from "@/utils/request";

export function GetMain(query) {
  return request({
    url: "/api/pages/main",
    method: "get",
    params: query,
  });
}

export function GetAbout(query) {
  return request({
    url: "/api/pages/about",
    method: "get",
    params: query,
  });
}

export function GetOKI(query) {
  return request({
    url: "/api/pages/oki",
    method: "get",
    params: query,
  });
}

export function GetProjects(query) {
  return request({
    url: "/api/pages/projects",
    method: "get",
    params: query,
  });
}

export function GetPageId(id, query) {
  return request({
    url: `/api/pages/${id}`,
    method: "get",
    params: query,
  });
}
