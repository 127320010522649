import { useI18n } from "vue-i18n";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const { locale } = useI18n({
      useScope: "global",
    });

    return { locale };
  },
  computed: {
    paramsLocale() {
      return {
        locale:
          this.locale === process.env.VUE_APP_I18N_FALLBACK_LOCALE
            ? ""
            : this.locale,
      };
    },
    pathLocale() {
      return this.locale === process.env.VUE_APP_I18N_FALLBACK_LOCALE
        ? ""
        : `/${this.locale}`;
    },
  },
});
