export const datetimeFormats = {
  en: {
    date: {
      month: "long",
      day: "numeric",
      year: "numeric",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
  },
  ru: {
    date: {
      month: "long",
      day: "numeric",
      year: "numeric",
    },
    time: {
      hour: "numeric",
      minute: "numeric",
    },
  },
};
