import { createStore } from "vuex";

import { GetNews } from "@/api/News";
import { GetOKI } from "@/api/Pages";

export default createStore({
  state: {
    news: [],
    newsSettings: {},
    newsStatus: "loading",
    OKI: {
      pages: [],
      persons: [],
    },
    OKIStatus: "loading",
  },
  getters: {
    // возвращаем с 1 по 2 статью
    OKIPagesForNavPart1: (state) => {
      const { pages = [] } = state.OKI;
      return pages.slice(0, 2);
    },
    // возвращаем с 2 по 4 статью
    OKIPagesForNavPart2: (state) => {
      const { pages = [] } = state.OKI;
      return pages.slice(2, 4);
    },
    // возвращаем с 1 по 2 статью
    OKIPagesPart1: (state) => {
      const { pages = [] } = state.OKI;
      return pages.slice(0, 2);
    },
    // возвращаем с 2 статьи
    OKIPagesPart2: (state) => {
      const { pages = [] } = state.OKI;
      return pages.slice(2);
    },
  },
  actions: {
    async fetchNews({ commit }) {
      try {
        commit("setNewsStatus", "loading");
        const resp = await GetNews();
        const data = resp.data;

        commit("setNews", data);
        commit("setNewsStatus", "success");
      } catch (error) {
        commit("setNewsStatus", "error");
      }
    },
    async fetchOKI({ commit }) {
      try {
        commit("setOKIStatus", "loading");
        const resp = await GetOKI();
        const data = resp.data.data;

        commit("setOKI", data);
        commit("setOKIStatus", "success");
      } catch (error) {
        commit("setOKIStatus", "error");
      }
    },
  },
  mutations: {
    setNews(state, data) {
      state.news = data.data;
      state.newsSettings = data.settings;
    },
    setNewsStatus(state, status) {
      state.newsStatus = status;
    },
    setOKI(state, OKI) {
      state.OKI = OKI;
    },
    setOKIStatus(state, status) {
      state.OKIStatus = status;
    },
  },
  modules: {},
});
