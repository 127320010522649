export const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  } else {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
    // return { top: 0 };

    const position = {};

    return new Promise((resolve) => {
      // check if any matched route config has meta that requires scrolling to top
      if (to.matched.some((m) => m.meta.scrollToTop !== false)) {
        // coords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.top = 0;
      }

      resolve(position);
    });
  }
};
