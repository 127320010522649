<template>
  <div class="gallery">
    <div class="gallery__body">
      <transition name="fade" mode="out-in">
        <img v-if="toggle" :src="currentPic" alt="" />
      </transition>
      <transition name="fade" mode="out-in">
        <img v-if="!toggle" :src="currentPic" alt="" />
      </transition>
    </div>
    <div v-if="showControls" class="gallery__controls">
      <Button :msg="t('common_prev')" @click="prev" />
      <Button :msg="t('common_next')" @click="next" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

import Button from "@/components/Button";

export default defineComponent({
  name: "Gallery",
  props: {
    pics: Array,
  },
  components: {
    Button,
  },
  computed: {
    showControls() {
      return this.pics && this.pics.length > 1 ? true : false;
    },
    currentPic() {
      return typeof this.pics[this.active] === "object"
        ? this.pics[this.active].path
        : this.pics[this.active];
    },
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t, locale };
  },
  data() {
    return {
      active: 0,
      toggle: false,
    };
  },
  methods: {
    prev() {
      this.toggle = !this.toggle;

      if (this.active === 0) {
        this.active = this.pics.length - 1;
        return;
      }
      this.active--;
    },
    next() {
      this.toggle = !this.toggle;

      if (this.active >= this.pics.length - 1) {
        this.active = 0;
        return;
      }
      this.active++;
    },
  },
});
</script>

<style scoped lang="scss">
.gallery {
  position: relative;
  &__body {
    position: relative;
    height: 100%;
    @include mobile {
      height: valM(236);
    }
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
    }
  }
  &__controls {
    @include mobile {
      display: inline-grid;
      grid-auto-flow: column;
      justify-content: end;
      width: 100%;
      margin-top: valM(10);
      gap: valM(14);
    }
    @include desktop {
      right: 0;
      bottom: 0;
      position: absolute;
      display: grid;
      grid-auto-flow: column;
      gap: val(14);
      margin: val(14);
    }
  }
}
</style>
