import { createRouter, createWebHistory } from "vue-router";
import { scrollBehavior } from "../utils/scrollBehavior";
import Home from "../views/Home.vue";

const locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.replaceAll(",", "|");

const routes = [
  {
    path: `/:locale(${locales})?/`,
    name: "Home",
    component: Home,
  },
  {
    path: "/:locale?/news",
    name: "News",
    component: () => import(/* webpackChunkName: "news" */ "../views/News.vue"),
  },
  {
    path: "/:locale?/news/:id",
    name: "NewsItem",
    component: () =>
      import(/* webpackChunkName: "newsItem" */ "../views/NewsItem.vue"),
  },
  {
    path: "/:locale?/office",
    name: "Office",
    component: () =>
      import(/* webpackChunkName: "office" */ "../views/Office.vue"),
  },
  {
    path: "/:locale?/edutainment",
    name: "Edutainment",
    component: () =>
      import(/* webpackChunkName: "edutainment" */ "../views/Edutainment.vue"),
  },
  {
    path: "/:locale?/QALC",
    name: "QALC",
    component: () => import(/* webpackChunkName: "qalc" */ "../views/QALC.vue"),
  },
  {
    path: "/:locale?/education",
    name: "Education",
    component: () =>
      import(/* webpackChunkName: "education" */ "../views/Education.vue"),
  },
  {
    path: "/:locale?/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/:locale?/projects",
    name: "Projects",
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/Projects.vue"),
  },
  {
    path: "/:locale?/innovation",
    name: "Innovation",
    component: () =>
      import(/* webpackChunkName: "innovation" */ "../views/Innovation.vue"),
  },
  {
    path: "/:locale?/social",
    name: "Social",
    component: () =>
      import(/* webpackChunkName: "social" */ "../views/Social.vue"),
  },
  {
    path: "/:locale?/science",
    name: "Science",
    component: () =>
      import(/* webpackChunkName: "science" */ "../views/Science.vue"),
  },
  {
    path: "/:locale?/:id",
    name: "Article",
    component: () =>
      import(/* webpackChunkName: "article" */ "../views/Article.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior,
  routes,
});

export default router;
