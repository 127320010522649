<template>
  <div class="grid-column grid-column_type_header">
    <div class="header-logo">
      <router-link
        class="logo"
        :to="{
          name: 'Home',
          params: paramsLocale,
        }"
      >
        <img
          src="@/assets/logo.svg"
          width="195"
          height="54"
          alt="Qatari-Russian Center for Cooperation"
        />
      </router-link>
    </div>
  </div>
  <div
    class="grid-column grid-column_type_header"
    @mouseleave="isOpenMenu1 = false"
  >
    <div class="header-menu">
      <router-link
        :to="{
          name: 'About',
          params: paramsLocale,
        }"
        class="menu menu_type_extended"
        :class="{ menu_state_open: isOpenMenu1 }"
        @mouseenter="isOpenMenu1 = true"
      >
        {{ t("nav_about") }}
      </router-link>
    </div>
    <div v-show="isOpenMenu1" class="header-submenu">
      <ul>
        <li>
          <a
            :href="t(`nav_qr_link_investment`)"
            target="_blank"
            rel="noopener noreferrer"
            >QR Investment & Trade Advisory</a
          >
        </li>
        <li>
          <a
            :href="t(`nav_qr_link_cultural`)"
            target="_blank"
            rel="noopener noreferrer"
            >Cultural Creative Agency</a
          >
        </li>
        <li>
          <a
            :href="t(`nav_qr_link_experience`)"
            target="_blank"
            rel="noopener noreferrer"
            >QR Experience</a
          >
        </li>
        <li>
          <a
            :href="t(`nav_qr_link_sports`)"
            target="_blank"
            rel="noopener noreferrer"
            >QR Sports</a
          >
        </li>
        <li>
          <a
            :href="t(`nav_qr_link_media`)"
            target="_blank"
            rel="noopener noreferrer"
            >QR Media</a
          >
        </li>
      </ul>
    </div>
  </div>
  <div
    class="grid-column grid-column_type_header"
    @mouseleave="isOpenMenu2 = false"
  >
    <div class="header-menu">
      <router-link
        :to="{
          name: 'Office',
          params: paramsLocale,
        }"
        class="menu menu_type_extended"
        :class="{ menu_state_open: isOpenMenu2 }"
        @mouseenter="isOpenMenu2 = true"
        v-html="t(`nav_office`)"
      >
      </router-link>
    </div>
    <div v-show="isOpenMenu2" class="header-submenu">
      <ul>
        <li>
          <router-link
            :to="{
              name: 'Education',
              params: paramsLocale,
            }"
            >{{ t("nav_education") }}</router-link
          >
        </li>
        <li v-for="(item, index) in OKIPagesForNavPart1" :key="index">
          <router-link
            :to="{
              name: 'Article',
              params: Object.assign({ id: item.id }, paramsLocale),
            }"
            >{{ item.title }}</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Innovation',
              params: paramsLocale,
            }"
            >{{ t("nav_innovation") }}</router-link
          >
        </li>
        <li v-for="(item, index) in OKIPagesForNavPart2" :key="index">
          <router-link
            :to="{
              name: 'Article',
              params: Object.assign({ id: item.id }, paramsLocale),
            }"
            >{{ item.title }}</router-link
          >
        </li>
      </ul>
    </div>
  </div>
  <div class="grid-column grid-column_type_header">
    <div class="header-grid">
      <div class="header-menu">
        <router-link
          :to="{
            name: 'News',
            params: paramsLocale,
          }"
          class="menu"
          >{{ t("nav_news") }}</router-link
        >
      </div>
      <a
        :href="t(`nav_spief_link`)"
        target="_blank"
        rel="noopener noreferrer"
        class="header-caption"
        >{{ t("nav_spief") }}</a
      >
    </div>
  </div>
  <div class="grid-column grid-column_type_header">
    <div class="header-grid">
      <div class="header-menu">
        <router-link
          :to="{
            name: 'Projects',
            params: paramsLocale,
          }"
          class="menu"
          >{{ t("nav_projects") }}</router-link
        >
      </div>
      <div>
        <div class="header-lang">
          <Lang />
        </div>
      </div>
      <a
        :href="t(`nav_culture_link`)"
        target="_blank"
        rel="noopener noreferrer"
        class="header-caption"
        >{{ t("nav_culture") }}</a
      >
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { mapGetters } from "vuex";

import Lang from "@/components/Lang";

export default defineComponent({
  name: "Header",
  components: {
    Lang,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  computed: {
    ...mapGetters(["OKIPagesForNavPart1", "OKIPagesForNavPart2"]),
  },
  data() {
    return {
      isOpenMenu1: false,
      isOpenMenu2: false,
    };
  },
});
</script>

<style scoped lang="scss">
@include mobile {
  .grid-column {
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5) {
      display: none;
    }
  }
}
.header {
  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr auto;
    grid-auto-flow: row;
  }
  &-menu {
  }
  &-submenu {
    display: flex;
    align-items: flex-end;
    ul {
      @include reset-list;
      li {
        a {
          word-break: break-word;
          color: var(--borderColor);
          text-decoration: none;
          line-height: 133.5%;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
  &-lang {
    display: flex;
    justify-content: flex-end;
    position: relative;
    .header-submenu {
      position: absolute;
      top: 100%;
      right: 0;
      text-align: right;
      li {
        margin-top: val(9);
        a {
          line-height: 1;
        }
      }
    }
  }
  &-caption {
    grid-column: span 2;
    height: val(35);
    border: 1px solid #6f6f6e;
    border-radius: 100px;
    display: grid;
    place-items: center;
    text-decoration: none;
    color: #000000;
    text-align: center;
    padding-left: val(10);
    padding-right: val(10);
    line-height: 1;
  }
  &-logo {
    @include mobile {
      display: flex;
      border-bottom: 1px solid var(--borderColor);
      height: valM(80);
      align-items: center;
    }
  }
}

.logo {
  display: inline-block;
  img {
    @include mobile {
      width: valM(149);
    }
    width: val(195);
  }
}

.menu {
  @include reset-button;
  text-align: left;
  text-decoration: none;
  &_type_extended {
    position: relative;
    width: 100%;
    display: block;
    &::before {
      position: absolute;
      top: val(6);
      right: 0;
      width: val(15);
      height: val(8);
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7299.1719l-7.28 7.28L.17.1719' stroke='%23000' stroke-width='.4727' stroke-miterlimit='10'/%3E%3C/svg%3E");
      background-size: contain;
      content: "";
    }
    &-right {
      &::before {
        right: calc(100% + #{val(15)});
      }
    }
  }
  &_state_open {
    &::before {
      transform: rotate(180deg);
    }
  }
}
</style>
