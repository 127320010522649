<template>
  <vue3-progress />
  <div class="grid grid-rows grid-top">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="grid">
    <HeaderMobile v-if="isMobile" />
    <Header v-else />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { mapActions, mapState } from "vuex";

import Header from "@/components/Header.vue";
import HeaderMobile from "@/components/HeaderMobile.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  components: {
    Header,
    HeaderMobile,
    Footer,
  },
  setup() {
    const { locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { locale };
  },
  ...mapState(["OKIStatus"]),
  watch: {
    locale() {
      this.fetchData(true);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["fetchOKI"]),
    fetchData(reload) {
      if (reload || this.OKIStatus !== "success") {
        this.fetchOKI();
      }
    },
  },
});
</script>

<style src="@/styles/index.scss" lang="scss" />
