<template>
  <div class="grid-column grid-column_row_2">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top grid-column__inner_position_left"
    >
      <h3 class="h3 upper">{{ t(`QRCCGroup1`) }}</h3>
    </div>
  </div>
  <div class="grid-column grid-column_row_2">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top"
    ></div>
  </div>
  <div class="grid-column grid-column_row_2">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top grid-column__inner_position_right"
    >
      <img :src="require(`@/assets/qrcc-group.jpg`)" alt="" />
    </div>
  </div>
  <div class="grid-column">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom grid-column__inner_position_left"
    >
      <div class="grid-inside">
        <p class="color-grey">Office of Knowledge and Innovation</p>
        <router-link
          :to="{
            name: 'Office',
            params: paramsLocale,
          }"
          class="title-big"
          >OKI</router-link
        >
      </div>
    </div>
  </div>
  <div class="grid-column">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom"
    >
      <div class="grid-inside">
        <p class="color-grey">QR Experience</p>
        <a
          :href="t(`nav_qr_link_experience`)"
          target="_blank"
          rel="noopener noreferrer"
          class="title-big"
          >QRE</a
        >
      </div>
    </div>
  </div>
  <div class="grid-column">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom"
    >
      <div class="grid-inside">
        <p class="color-grey">Cultural Creative Agency</p>
        <a
          :href="t(`nav_qr_link_cultural`)"
          target="_blank"
          rel="noopener noreferrer"
          class="title-big"
          >CCA</a
        >
      </div>
    </div>
  </div>
  <div class="grid-column">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom"
    >
      <div class="grid-inside">
        <p class="color-grey">QR Sport</p>
        <a
          :href="t(`nav_qr_link_sports`)"
          target="_blank"
          rel="noopener noreferrer"
          class="title-big"
          >QRS</a
        >
      </div>
    </div>
  </div>
  <div class="grid-column">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom grid-column__inner_position_right"
    >
      <div class="grid-inside">
        <p class="color-grey">QR Investment & Trade Advisory</p>
        <a
          :href="t(`nav_qr_link_investment`)"
          target="_blank"
          rel="noopener noreferrer"
          class="title-big"
          >QRITA</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

export default defineComponent({
  name: "QRCCGroup",
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t, locale };
  },
});
</script>

<style scoped lang="scss">
@include desktop {
  .grid-column {
    &:nth-of-type(26) {
      grid-column: span 3;
    }
    &:nth-of-type(27),
    &:nth-of-type(28),
    &:nth-of-type(29),
    &:nth-of-type(30),
    &:nth-of-type(31) {
      z-index: 1;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "QRCCGroup1": "QRCC GROUP",
  },
  "ru": {
    "QRCCGroup1": "Группа компаний QRCC",
  },
  "ar": {
    "QRCCGroup1": "مجموعة مركز التعاون القطري الروسي ",
  }
}
</i18n>
