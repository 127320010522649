const breakpoints = {
  xs: 0,
  sm: 576,
  md: 767,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

/**
 * Миксин добавляет глобальные переменные:
 * device {String} (mobile, tablet, desktop)
 * breakpoints {String} (xs, sm, md, lg, xl, xxl)
 */
export default {
  computed: {
    isMobile() {
      if (this.breakpoints === "xs" || this.breakpoints === "sm") {
        return true;
      } else if (this.breakpoints === "md" || this.breakpoints === "lg") {
        return false;
      }
      return false;
    },
  },
  data() {
    return {
      breakpoints: "xs",
    };
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      const width = window.innerWidth;

      if (width <= breakpoints.sm) {
        this.breakpoints = "xs";
      } else if (width > breakpoints.sm && width <= breakpoints.md) {
        this.breakpoints = "sm";
      } else if (width > breakpoints.md && width <= breakpoints.lg) {
        this.breakpoints = "md";
      } else if (width > breakpoints.lg && width <= breakpoints.xl) {
        this.breakpoints = "lg";
      } else if (width > breakpoints.xl && width <= breakpoints.xxl) {
        this.breakpoints = "xl";
      } else if (width > breakpoints.xxl) {
        this.breakpoints = "xxl";
      }
    },
  },
};
