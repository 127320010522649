<template>
  <header class="header">
    <div class="header-logo">
      <router-link
        class="logo"
        :to="{
          name: 'Home',
          params: paramsLocale,
        }"
      >
        <img
          src="@/assets/logo.svg"
          width="195"
          height="54"
          alt="Qatari-Russian Center for Cooperation"
        />
      </router-link>
    </div>
    <div class="header-controls">
      <div class="header-lang">
        <Lang />
        <!-- <button
          class="header-lang__button"
          :class="{ 'header-lang__button_state_open': isOpenMenu3 }"
          @click="isOpenMenu3 = !isOpenMenu3"
        >
          En
        </button>
        <div v-show="isOpenMenu3" class="header-lang__submenu">
          <ul>
            <li><router-link to="/">Ru</router-link></li>
            <li><router-link to="/">Ab</router-link></li>
          </ul>
        </div> -->
      </div>
      <button
        class="header-button-menu"
        :class="{ 'header-button-menu_state_open': isOpenMenu }"
        @click="isOpenMenu = !isOpenMenu"
      >
        {{ t("common_menu") }}
      </button>
    </div>
  </header>
  <nav v-if="isOpenMenu" class="nav">
    <div class="nav__inner">
      <div class="header-menu">
        <button
          class="menu menu_type_extended"
          :class="{ menu_state_open: isOpenMenu1 }"
          @click="isOpenMenu1 = !isOpenMenu1"
        >
          {{ t("nav_about") }}
        </button>
      </div>
      <div v-show="isOpenMenu1" class="header-submenu">
        <ul>
          <li @click="isOpenMenu = false">
            <router-link
              :to="{
                name: 'About',
                params: paramsLocale,
              }"
              >{{ t("nav_about") }}</router-link
            >
          </li>
          <li>
            <a
              :href="t(`nav_qr_link_investment`)"
              target="_blank"
              rel="noopener noreferrer"
              >QR Investment & Trade Advisory</a
            >
          </li>
          <li>
            <a
              :href="t(`nav_qr_link_cultural`)"
              target="_blank"
              rel="noopener noreferrer"
              >Cultural Creative Agency</a
            >
          </li>
          <li>
            <a
              :href="t(`nav_qr_link_experience`)"
              target="_blank"
              rel="noopener noreferrer"
              >QR Experience</a
            >
          </li>
          <li>
            <a
              :href="t(`nav_qr_link_sports`)"
              target="_blank"
              rel="noopener noreferrer"
              >QR Sports</a
            >
          </li>
          <li>
            <a
              :href="t(`nav_qr_link_media`)"
              target="_blank"
              rel="noopener noreferrer"
              >QR Media</a
            >
          </li>
        </ul>
      </div>
      <div class="header-menu">
        <button
          class="menu menu_type_extended"
          :class="{ menu_state_open: isOpenMenu2 }"
          @click="isOpenMenu2 = !isOpenMenu2"
        >
          Office of Knowledge<br />and Innovation
        </button>
      </div>
      <div v-show="isOpenMenu2" class="header-submenu">
        <ul>
          <li @click="isOpenMenu = false">
            <router-link
              :to="{
                name: 'Office',
                params: paramsLocale,
              }"
              >Office of Knowledge and Innovation</router-link
            >
          </li>
          <li @click="isOpenMenu = false">
            <router-link
              :to="{
                name: 'Education',
                params: paramsLocale,
              }"
              >{{ t("nav_education") }}</router-link
            >
          </li>
          <li
            v-for="(item, index) in OKIPagesForNavPart1"
            :key="index"
            @click="isOpenMenu = false"
          >
            <router-link
              :to="{
                name: 'Article',
                params: Object.assign({ id: item.id }, paramsLocale),
              }"
              >{{ item.title }}</router-link
            >
          </li>
          <li @click="isOpenMenu = false">
            <router-link
              :to="{
                name: 'Innovation',
                params: paramsLocale,
              }"
              >{{ t("nav_innovation") }}</router-link
            >
          </li>
          <li
            v-for="(item, index) in OKIPagesForNavPart2"
            :key="index"
            @click="isOpenMenu = false"
          >
            <router-link
              :to="{
                name: 'Article',
                params: Object.assign({ id: item.id }, paramsLocale),
              }"
              >{{ item.title }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class="header-menu" @click="isOpenMenu = false">
        <router-link
          :to="{
            name: 'News',
            params: paramsLocale,
          }"
          class="menu"
          >{{ t("nav_news") }}</router-link
        >
      </div>
      <div class="header-menu" @click="isOpenMenu = false">
        <router-link
          :to="{
            name: 'Projects',
            params: paramsLocale,
          }"
          class="menu"
          >{{ t("nav_projects") }}</router-link
        >
      </div>
      <div class="header-menu">
        <a
          :href="t(`nav_culture_link`)"
          target="_blank"
          rel="noopener noreferrer"
          class="menu"
          >{{ t("nav_culture") }}</a
        >
      </div>
      <div class="header-menu">
        <a
          :href="t(`nav_spief_link`)"
          target="_blank"
          rel="noopener noreferrer"
          class="menu"
          >{{ t("nav_spief") }}</a
        >
      </div>
    </div>
  </nav>
  <div v-if="isOpenMenu" class="nav__back">
    <div class="nav__back-inner"></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { mapGetters } from "vuex";

import Lang from "@/components/Lang";

export default defineComponent({
  name: "HeaderMobile",
  components: {
    Lang,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t, locale };
  },
  computed: {
    ...mapGetters(["OKIPagesForNavPart1", "OKIPagesForNavPart2"]),
  },
  data() {
    return {
      isOpenMenu: false,
      isOpenMenu1: false,
      isOpenMenu2: false,
    };
  },
});
</script>

<style scoped lang="scss">
.header {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto;
  padding-top: valM(20);
  padding-bottom: valM(20);
  border-bottom: 1px solid var(--borderColor);
  position: relative;
  z-index: 110;
  &-controls {
    display: grid;
    grid-auto-flow: column;
    align-items: start;
    gap: valM(26);
  }
  &-menu {
    margin-top: valM(20);
  }
  &-submenu {
    margin-top: valM(10);
    padding-bottom: valM(10);
    display: flex;
    align-items: flex-end;
    ul {
      @include reset-list;
      li {
        a {
          word-break: break-word;
          color: var(--borderColor);
          text-decoration: none;
          line-height: 133.5%;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
  &-lang {
    position: relative;
    z-index: 100;
  }
  &-button-menu {
    @include reset-button;
    font-size: valM(16);
    position: relative;
    &_state_open {
      color: transparent;
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: valM(-10);
        width: valM(21);
        height: 1px;
        background-color: var(--borderColor);
        content: "";
        transform: rotate(45deg);
      }
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: valM(-10);
        width: valM(21);
        height: 1px;
        background-color: var(--borderColor);
        content: "";
        transform: rotate(-45deg);
      }
    }
  }
  &-logo {
  }
}

.logo {
  display: inline-block;
  img {
    @include mobile {
      width: valM(149);
    }
  }
}

.nav {
  position: absolute;
  top: valM(80);
  left: 0;
  right: 0;
  height: calc(100vh - #{valM(80)});
  z-index: 100;
  &__inner {
    margin: 0 auto;
    width: calc(100vw - #{valM(28)});
    padding: 0 valM(10);
  }
  &__back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bgColor);
    z-index: 30;
    &-inner {
      margin: 0 auto;
      width: calc(100vw - #{valM(28)});
      border-left: 1px solid var(--borderColor);
      border-right: 1px solid var(--borderColor);
      height: 100%;
    }
  }
}

.menu {
  @include reset-button;
  text-align: left;
  text-decoration: none;
  font-size: valM(26);
  line-height: valM(29);
  &_type_extended {
    position: relative;
    width: 100%;
    display: block;
    padding-right: valM(50);
    &::before {
      position: absolute;
      top: valM(12);
      right: 0;
      width: valM(15);
      height: valM(8);
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7299.1719l-7.28 7.28L.17.1719' stroke='%23000' stroke-width='.4727' stroke-miterlimit='10'/%3E%3C/svg%3E");
      background-size: contain;
      content: "";
    }
    &-right {
      &::before {
        right: calc(100% + #{val(15)});
      }
    }
  }
  &_state_open {
    &::before {
      transform: rotate(180deg);
    }
  }
}
</style>

<style lang="scss">
html[dir="rtl"] {
  .menu {
    @include mobile {
      text-align: right;
      &_type_extended {
        padding-right: 0;
        padding-left: valM(50);
        &::before {
          left: 0;
          right: auto;
        }
      }
    }
  }
}
</style>
