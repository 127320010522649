<template>
  <router-link :to="`${pathLocale}/news/${id}`" class="card grid-inside">
    <div class="card__head">
      <h3 class="h3 card__title" v-html="title"></h3>
    </div>
    <div class="card__body">
      <p v-if="date" class="small color-grey card__date">
        <span>{{ d(date, "date") }}</span>
        <span>{{ d(date, "time") }}</span>
      </p>
      <div class="card__pic">
        <img v-if="image" :src="image" alt="" />
        <div v-else class="card__empty"></div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { datetimeFormats } from "@/utils/formats";
import { mediaUrl } from "@/utils";

export default defineComponent({
  name: "NewsCard",
  props: {
    id: Number,
    title: String,
    date: String,
    image: String,
  },
  setup() {
    const { t, d, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      datetimeFormats,
    });

    return { t, d, locale };
  },
  data() {
    return {
      mediaUrl,
    };
  },
});
</script>

<style scoped lang="scss">
.card {
  text-decoration: none !important;
  &__title {
    min-height: val(190);
    overflow: hidden;
    margin-bottom: val(18);
    word-break: break-word;
    @include mobile {
      margin-bottom: valM(18);
    }
  }
  &__date {
    display: flex;
    justify-content: space-between;
    @include mobile {
      margin-bottom: valM(18);
    }
    margin-bottom: val(14);
  }
  &__pic {
    img {
      width: 100%;
    }
  }
  &__empty {
    min-height: val(273);
    @include mobile {
      min-height: 0;
    }
  }
}
</style>
