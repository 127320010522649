<template>
  <div class="promo">
    <div class="promo__inner">
      <div v-if="isVideo" class="promo__media">
        <video
          v-if="isMobile"
          ref="video"
          :poster="srcPicMobile"
          :src="srcVideoMobile"
          autoplay
          playsinline
          loop
          muted
        ></video>
        <video
          v-else
          ref="video"
          :poster="srcPic"
          :src="srcVideo"
          autoplay
          playsinline
          loop
          muted
        ></video>
        <Button
          class="btn-default promo__button-mute"
          :class="{ unmute: !muted }"
          @click="onMute"
        />
      </div>
      <picture v-else>
        <source media="(max-width: 767px)" :srcset="srcPicMobile" />
        <source media="(min-width: 768px)" :srcset="srcPic" />
        <img class="promo__pic" :src="srcPic" alt="" />
      </picture>
      <slot />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  name: "Promo",
  components: {
    Button,
  },
  props: {
    srcPic: String,
    srcPicMobile: String,
    srcVideo: String,
    srcVideoMobile: String,
    isVideo: [Boolean, Number],
  },
  watch: {
    isMobile() {
      this.muted = true;
    },
  },
  data() {
    return {
      muted: true,
    };
  },
  methods: {
    onMute() {
      this.$refs.video.muted = !this.muted;
      this.muted = !this.muted;
    },
  },
};
</script>

<style scoped lang="scss">
.promo {
  position: relative;
  z-index: 20;
  &_full_width {
    &::before {
      position: absolute;
      top: val(15);
      left: val(-15);
      height: calc(100% - #{val(15)} * 2);
      width: 1px;
      background-color: #fff;
      content: "";
      z-index: 1;
      @include mobile {
        top: valM(30);
        left: valM(-10);
        height: calc(100% - #{valM(30)} * 2);
      }
    }
    &::after {
      position: absolute;
      top: val(15);
      right: val(-15);
      height: calc(100% - #{val(15)} * 2);
      width: 1px;
      background-color: #fff;
      content: "";
      @include mobile {
        top: valM(30);
        right: valM(-10);
        height: calc(100% - #{valM(30)} * 2);
      }
    }
    .promo {
      &__inner {
        padding: val(15) 0;
        background-color: var(--bgColor);
        margin: calc(#{val(-15)} + 1px) val(-64 - 15);
        @include mobile {
          padding: valM(15) 0;
          margin: 0 calc(#{valM(-10 - 14)} - 1px);
        }
      }
    }
  }
  &_full_width_only_mobile {
    &::before {
      @include mobile {
        position: absolute;
        width: 1px;
        background-color: #fff;
        content: "";
        top: valM(30);
        left: valM(-10);
        height: calc(100% - #{valM(30)} * 2);
      }
    }
    &::after {
      @include mobile {
        position: absolute;
        width: 1px;
        background-color: #fff;
        content: "";
        top: valM(30);
        right: valM(-10);
        height: calc(100% - #{valM(30)} * 2);
      }
    }
    .promo {
      &__inner {
        @include mobile {
          background-color: var(--bgColor);
          padding: valM(15) 0;
          margin: 0 calc(#{valM(-10 - 14)} - 1px);
        }
      }
    }
  }
  &_type_content {
    @include desktop {
      overflow: hidden;
    }
    .promo {
      &__inner {
        &::before {
          position: absolute;
          width: 100vw;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgb(29, 29, 27, 0.85) 0%,
            rgba(29, 29, 27, 0) 30%,
            rgba(29, 29, 27, 0) 70%,
            rgb(29, 29, 27, 0.85) 100%
          );
          content: "";
          // z-index: 10;
        }
      }
    }
    @include mobile {
      &::before {
        position: absolute;
        top: valM(30);
        left: calc(#{valM(-10)} - 1px);
        height: calc(100% - #{valM(30)} * 2);
        width: 1px;
        background-color: #fff;
        content: "";
        z-index: 1;
      }
      &::after {
        position: absolute;
        top: valM(30);
        right: calc(#{valM(-10)} - 1px);
        height: calc(100% - #{valM(30)} * 2);
        width: 1px;
        background-color: #fff;
        content: "";
        z-index: 1;
      }
      .promo {
        &__inner {
          // position: relative;
          background-color: var(--bgColor);
          padding: valM(15) 0;
          margin: 0 calc(#{valM(-10 - 14)} - 1px);
          &::before {
            height: calc(100% - #{valM(30)});
          }
        }
      }
    }
  }
  &__pic {
    display: block;
    width: 100%;
  }
  &__media {
    position: relative;
    video {
      display: block;
      width: 100%;
    }
  }
  &__button-mute {
    position: absolute;
    bottom: val(14);
    right: val(80);
    background-image: url("data:image/svg+xml,%3Csvg width='59' height='35' viewBox='0 0 59 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='35.3536' y1='13.2128' x2='44.3536' y2='22.2128' stroke='white'/%3E%3Cline x1='34.6464' y1='22.2129' x2='43.6464' y2='13.2129' stroke='white'/%3E%3Cmask id='path-3-inside-1' fill='white'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.0714 14.8654C20.9191 14.9536 20.7462 15 20.5703 15H17C16.4477 15 16 15.4477 16 16V17.8022V20C16 20.5523 16.4477 21 17 21H21.2537C21.4296 21 21.6025 21.0464 21.7548 21.1346L29.6988 25.7348C30.3655 26.1208 31.1999 25.6398 31.1999 24.8694L31.1999 10.7347C31.1999 9.96427 30.3655 9.48322 29.6988 9.86928L21.0714 14.8654Z'/%3E%3C/mask%3E%3Cpath d='M31.1999 10.7347L30.1999 10.7347L31.1999 10.7347ZM21.7548 21.1346L21.2537 22L21.7548 21.1346ZM21.0714 14.8654L20.5703 14L21.0714 14.8654ZM29.6988 25.7348L29.1977 26.6002L29.6988 25.7348ZM17 16H20.5703V14H17V16ZM17 17.8022V16H15V17.8022H17ZM17 20V17.8022H15V20H17ZM21.2537 20H17V22H21.2537V20ZM21.2537 22L29.1977 26.6002L30.1999 24.8694L22.2559 20.2692L21.2537 22ZM32.1999 24.8694L32.1999 10.7347L30.1999 10.7347L30.1999 24.8694L32.1999 24.8694ZM29.1977 9.00391L20.5703 14L21.5725 15.7307L30.1999 10.7347L29.1977 9.00391ZM32.1999 10.7347C32.1999 9.19389 30.531 8.23178 29.1977 9.00391L30.1999 10.7347L30.1999 10.7347L32.1999 10.7347ZM21.2537 22L21.2537 22L22.2559 20.2692C21.9513 20.0929 21.6056 20 21.2537 20V22ZM15 20C15 21.1046 15.8954 22 17 22V20L17 20H15ZM20.5703 16C20.9222 16 21.268 15.9071 21.5725 15.7307L20.5703 14L20.5703 14V16ZM29.1977 26.6002C30.531 27.3723 32.1999 26.4102 32.1999 24.8694L30.1999 24.8694L30.1999 24.8694L29.1977 26.6002ZM17 14C15.8954 14 15 14.8954 15 16H17L17 16V14Z' fill='white' mask='url(%23path-3-inside-1)'/%3E%3C/svg%3E%0A") !important;
    background-repeat: no-repeat !important;
    background-position: 50% 50% !important;
    background-size: val(59) auto !important;
    min-width: val(60);
    @include mobile {
      bottom: valM(14);
      right: valM(26);
      background-size: valM(39) auto !important;
      min-width: valM(45);
    }
    &.unmute {
      background-image: url("data:image/svg+xml,%3Csvg width='59' height='35' viewBox='0 0 59 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35 21C37.2091 21 39 19.433 39 17.5C39 15.567 37.2091 14 35 14' stroke='white'/%3E%3Cpath d='M35 25C38.866 25 42 21.6421 42 17.5C42 13.3579 38.866 10 35 10' stroke='white'/%3E%3Cmask id='path-3-inside-1' fill='white'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.0714 14.8654C20.9191 14.9536 20.7462 15 20.5703 15H17C16.4477 15 16 15.4477 16 16V17.8022V20C16 20.5523 16.4477 21 17 21H21.2537C21.4296 21 21.6025 21.0464 21.7548 21.1346L29.6988 25.7348C30.3655 26.1208 31.1999 25.6398 31.1999 24.8694L31.1999 10.7347C31.1999 9.96427 30.3655 9.48322 29.6988 9.86928L21.0714 14.8654Z'/%3E%3C/mask%3E%3Cpath d='M31.1999 10.7347L30.1999 10.7347L31.1999 10.7347ZM21.7548 21.1346L21.2537 22L21.7548 21.1346ZM21.0714 14.8654L20.5703 14L21.0714 14.8654ZM29.6988 25.7348L29.1977 26.6002L29.6988 25.7348ZM17 16H20.5703V14H17V16ZM17 17.8022V16H15V17.8022H17ZM17 20V17.8022H15V20H17ZM21.2537 20H17V22H21.2537V20ZM21.2537 22L29.1977 26.6002L30.1999 24.8694L22.2559 20.2692L21.2537 22ZM32.1999 24.8694L32.1999 10.7347L30.1999 10.7347L30.1999 24.8694L32.1999 24.8694ZM29.1977 9.00391L20.5703 14L21.5725 15.7307L30.1999 10.7347L29.1977 9.00391ZM32.1999 10.7347C32.1999 9.19389 30.531 8.23178 29.1977 9.00391L30.1999 10.7347L30.1999 10.7347L32.1999 10.7347ZM21.2537 22L21.2537 22L22.2559 20.2692C21.9513 20.0929 21.6056 20 21.2537 20V22ZM15 20C15 21.1046 15.8954 22 17 22V20L17 20H15ZM20.5703 16C20.9222 16 21.268 15.9071 21.5725 15.7307L20.5703 14L20.5703 14V16ZM29.1977 26.6002C30.531 27.3723 32.1999 26.4102 32.1999 24.8694L30.1999 24.8694L30.1999 24.8694L29.1977 26.6002ZM17 14C15.8954 14 15 14.8954 15 16H17L17 16V14Z' fill='white' mask='url(%23path-3-inside-1)'/%3E%3C/svg%3E%0A") !important;
    }
  }
}
</style>
