<template>
  <div class="lang">
    <button
      class="lang__button"
      :class="{ lang__button_state_open: isOpen }"
      @click="isOpen = !isOpen"
    >
      {{ selectedLocale }}
    </button>
    <div v-show="isOpen" class="lang__submenu">
      <ul>
        <li v-for="(item, index) in unselectedLocales" :key="index">
          <button @click="onChange(item)">{{ item }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Lang",
  setup() {
    const { locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { locale };
  },
  computed: {
    unselectedLocales() {
      return this.locales.filter((e) => e !== this.locale);
    },
    selectedLocale() {
      const lang = this.locales.filter((e) => e === this.locale);
      return lang.length ? lang[0] : this.locales[0];
    },
  },
  data() {
    return {
      isOpen: false,
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    };
  },
  methods: {
    onChange(locale) {
      const { fullPath } = this.$route;
      const pathWithoutLocale = fullPath
        .split("/")
        .filter((e) => !this.locales.some((el) => el === e));

      if (locale !== this.locales[0]) {
        pathWithoutLocale.splice(1, 0, locale);
      }

      this.$router.push(pathWithoutLocale.join("/"));
      this.isOpen = false;
    },
  },
});
</script>

<style scoped lang="scss">
@include mobile {
  .lang {
    &__button {
      @include reset-button;
      font-size: valM(16);
      color: #a2a2a2;
      position: relative;
      text-transform: capitalize;
      &_state_open {
        &::before {
          transform: rotate(180deg);
        }
        &::after {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
        }
      }
      &::before {
        position: absolute;
        top: valM(7);
        margin-right: valM(5);
        margin-left: valM(5);
        right: 100%;
        width: valM(15);
        height: valM(8);
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7299.1719l-7.28 7.28L.17.1719' stroke='%23000' stroke-width='.4727' stroke-miterlimit='10'/%3E%3C/svg%3E");
        background-size: contain;
        content: "";
      }
    }
    &__submenu {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-top: valM(15);
      transform: translateX(-50%);
      width: valM(95);
      text-align: center;
      border-radius: valM(18);
      background-color: #fff;
      ul {
        @include reset-list;
        padding: valM(5) 0;
        li {
          padding: valM(5) 0;
          button {
            @include reset-button;
            color: var(--borderColor);
            text-decoration: none;
            line-height: 133.5%;
            text-transform: capitalize;
            &:hover {
              color: #000;
            }
          }
        }
      }
    }
  }
}
@include desktop {
  .lang {
    position: relative;
    &__button {
      @include reset-button;
      text-align: left;
      text-decoration: none;
      position: relative;
      text-transform: capitalize;
      &_state_open {
        &::before {
          transform: rotate(180deg);
        }
      }
      &::before {
        position: absolute;
        top: val(6);
        right: 100%;
        margin-right: val(15);
        margin-left: val(15);
        width: val(15);
        height: val(8);
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7299.1719l-7.28 7.28L.17.1719' stroke='%23000' stroke-width='.4727' stroke-miterlimit='10'/%3E%3C/svg%3E");
        background-size: contain;
        content: "";
      }
    }
    &__submenu {
      position: absolute;
      top: 100%;
      right: 0;
      ul {
        @include reset-list;
        padding-top: val(3);
        li {
          margin-top: val(5);
          button {
            @include reset-button;
            color: var(--borderColor);
            text-decoration: none;
            line-height: 133.5%;
            text-transform: capitalize;
            &:hover {
              color: #000;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
html[dir="rtl"] .lang__button::before {
  right: auto;
  left: 100%;
}
</style>
