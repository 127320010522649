<template>
  <div class="grid-column grid-column_type_footer grid-column_position_top">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top grid-column__inner_position_left"
    >
      <h3 class="h3 upper">{{ t(`footer1`) }}</h3>
    </div>
  </div>
  <div class="grid-column grid-column_type_footer">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top"
    >
      <div v-if="isMobile" class="pm-54"></div>
      <div class="footer-grid">
        <p class="color-grey">
          {{ t(`footer2`) }}<br />
          {{ t(`footer3`) }}
        </p>
        <div v-if="isMobile" class="pm-10"></div>
        <div class="footer-button">
          <Button
            class="btn-grey"
            :to="`https://www.google.com/maps/dir//55.7658907,37.6336171/@55.765891,37.633617,16z?hl=${t(
              `footer5link`
            )}`"
            target="_blank"
            :msg="t(`footer5`)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="grid-column grid-column_type_footer">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top"
    >
      <div v-if="isMobile" class="pm-24"></div>
      <p class="color-grey">
        {{ t(`footer4`) }}<br />
        <a href="mailto:qrcc@qatarrussia.ru">qrcc@qatarrussia.ru</a>
      </p>
      <div v-if="isMobile" class="pm-24"></div>
    </div>
  </div>
  <div class="grid-column grid-column_type_footer">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top"
    >
      <div v-if="isMobile" class="grid-line"></div>
      <p class="color-grey">
        {{ t(`footer7`) }}<br />
        <a
          href="https://www.instagram.com/qrcc.ru/?igshid=47ixmx8ez8h6"
          target="_blank"
          rel="noopener noreferrer"
          >{{ t(`footersocial1`) }}</a
        ><br />
        <a
          href="https://www.instagram.com/qrcc.qa/?igshid=i1ezmda9j5gg"
          target="_blank"
          rel="noopener noreferrer"
          >{{ t(`footersocial2`) }}</a
        ><br />
        <a
          href="https://www.facebook.com/Qatari-Russian-Center-for-Cooperation-100702605207595"
          target="_blank"
          rel="noopener noreferrer"
          >{{ t(`footersocial3`) }}</a
        ><br />
        <a
          href="https://www.linkedin.com/company/qatari-russian-center-for-cooperation/?viewAsMember=true"
          target="_blank"
          rel="noopener noreferrer"
          >{{ t(`footersocial4`) }}</a
        >
      </p>
      <div v-if="isMobile" class="pm-54"></div>
    </div>
  </div>
  <div class="grid-column grid-column_type_footer">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_top grid-column__inner_position_right"
    >
      <div v-if="isMobile" class="grid-line"></div>
      <p class="color-grey">
        <router-link
          :to="{
            name: 'About',
            params: paramsLocale,
          }"
        >
          {{ t("nav_about") }} </router-link
        ><br />
        <router-link
          :to="{
            name: 'Office',
            params: paramsLocale,
          }"
          v-html="t(`nav_office2`)"
        ></router-link
        ><br />
        <router-link
          :to="{
            name: 'News',
            params: paramsLocale,
          }"
          >{{ t("nav_news") }}</router-link
        ><br />
        <router-link
          :to="{
            name: 'Projects',
            params: paramsLocale,
          }"
          >{{ t("nav_projects") }}</router-link
        ><br />
        <a
          :href="t(`nav_culture_link`)"
          target="_blank"
          rel="noopener noreferrer"
          >{{ t("nav_culture") }}</a
        >
      </p>
      <div v-if="isMobile" class="pm-54"></div>
    </div>
  </div>
  <div class="grid-column grid-column_type_footer grid-column_place_bottom">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom grid-column__inner_position_left"
    >
      <div v-if="isMobile" class="grid-line"></div>
      <p class="color-grey-light">&copy; 2021</p>
    </div>
  </div>
  <div class="grid-column grid-column_type_footer grid-column_place_bottom">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom"
    >
      <p class="color-grey-light">
        <a href="http://" target="_blank" rel="noopener noreferrer">{{
          t(`footer6`)
        }}</a>
      </p>
      <div v-if="isMobile" class="pm-24"></div>
    </div>
  </div>
  <div class="grid-column grid-column_type_footer grid-column_place_bottom">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom"
    >
      <!-- <p class="color-grey-light">Typeface by Colophone</p> -->
    </div>
  </div>
  <div class="grid-column grid-column_type_footer grid-column_place_bottom">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom"
    >
      <!-- <p class="color-grey-light">Design by Made in Buro</p> -->
    </div>
  </div>
  <div class="grid-column grid-column_type_footer grid-column_place_bottom">
    <div
      class="grid-column__inner grid-column__inner_bg_white grid-column__inner_position_bottom grid-column__inner_position_right"
    >
      <!-- <p class="color-grey-light">Code by Ony</p> -->
      <div v-if="isMobile" class="pm-18"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

import Button from "@/components/Button";

export default defineComponent({
  name: "Footer",
  components: {
    Button,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t, locale };
  },
});
</script>

<style scoped lang="scss">
.footer {
  &-grid {
    display: grid;
    height: 100%;
    grid-template-rows: 1fr auto;
    grid-auto-flow: row;
  }
}
</style>

<i18n>
{
  "en": {
    "footer1": "Contacts",
    "footer2": "Address:",
    "footer3": "Sretenskiy blvrd 6/1, bldg 1, Moscow",
    "footer4": "Please contact us by email:",
    "footer5": "Map",
    "footer5link": "en",
    "footer6": "Confidentiality policy",
    "footer7": "Follow us:",
    "footersocial1": "Instagram (Russian)",
    "footersocial2": "Instagram (Arabic)",
    "footersocial3": "Facebook",
    "footersocial4": "LinkedIn",
  },
  "ru": {
    "footer1": "Контакты",
    "footer2": "Адрес:",
    "footer3": "Сретенский бульвар 6/1, строение 1, Москва",
    "footer4": "Пожалуйста, свяжитесь с нами по почте: ",
    "footer5": "Карта",
    "footer5link": "ru",
    "footer6": "Политика конфиденциальности",
    "footer7": "Мы в соцсетях:",
    "footersocial1": "Instagram (Русский)",
    "footersocial2": "Instagram (Арабский)",
    "footersocial3": "Facebook",
    "footersocial4": "LinkedIn",
  },
  "ar": {
    "footer1": "للتواصل",
    "footer2": "العنوان:",
    "footer3": "شارع سريتينسكي 6/1، البناية 1، موسكو",
    "footer4": "يرجى التواصل معنا عبر البريد الالكتروني:",
    "footer5": "الخارطة",
    "footer5link": "انجليزي",
    "footer6": "سياسة الخصوصية",
    "footer7": "تجدوننا على مواقع التواصل الاجتماعي",
    "footersocial1": "انستغرام (روسي) ",
    "footersocial2": "انستغرام (عربي) ",
    "footersocial3": "فيسبوك ",
    "footersocial4": "لينكيد اين",
  }
}
</i18n>
