import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixinDevice from "./mixins/device";
import mixinLocale from "./mixins/locale";
import i18n from "./i18n";
import Vue3Progress from "vue3-progress";

const options = {
  position: "fixed",
  height: "1px",
  color: "#808080",
};

function setBaseDirection(locale) {
  document.documentElement.setAttribute("lang", locale);

  if (locale === "ar") {
    document.documentElement.setAttribute("dir", "rtl");
  } else {
    document.documentElement.setAttribute("dir", "ltr");
  }
}
function setTitle(locale) {
  if (locale === "ru") {
    document.title = process.env.VUE_APP_TITLE_RU || "QRCC";
  } else {
    document.title = process.env.VUE_APP_TITLE_EN || "QRCC";
  }
}

function setDescription(locale) {
  const description = document.querySelector('meta[name="description"]');
  const ogDescription = document.querySelector(
    'meta[property="og:description"]'
  );
  const ogImage = document.querySelector('meta[property="og:image"]');

  if (locale === "ru") {
    let descRU = process.env.VUE_APP_DESCRIPTION_RU || "";
    let imageRU = `${process.env.VUE_APP_SITE_URL}/static/cover_ru.png`;

    description.setAttribute("content", descRU);
    ogDescription.setAttribute("content", descRU);
    ogImage.setAttribute("content", imageRU);
  } else {
    let descEN = process.env.VUE_APP_DESCRIPTION_EN || "";
    let imageEN = `${process.env.VUE_APP_SITE_URL}/static/cover.png`;

    description.setAttribute("content", descEN);
    ogDescription.setAttribute("content", descEN);
    ogImage.setAttribute("content", imageEN);
  }
}

router.beforeEach((to, from, next) => {
  const locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
  const { locale } = to.params;
  const isLocale = locales.some((e) => e === locale);

  setBaseDirection(locale);
  setTitle(locale);
  setDescription(locale);

  if (i18n.global.locale.value !== locale) {
    i18n.global.locale.value = isLocale
      ? locale
      : process.env.VUE_APP_I18N_FALLBACK_LOCALE;
  }

  next();
});

// params

createApp(App)
  .use(i18n)
  .use(store)
  .use(Vue3Progress, options)
  .use(router)
  .mixin(mixinDevice)
  .mixin(mixinLocale)
  .mount("#app");
