<template>
  <div class="group">
    <img :src="require(`@/assets/qrcc-group-m.jpg`)" alt="" />
    <div class="group-body">
      <h3 class="h3 upper">{{ t("QRCCGroupMobile1") }}</h3>
      <transition name="fade" mode="out-in">
        <div v-if="active === 0" class="group-body__item">
          <router-link
            :to="{
              name: 'Office',
              params: paramsLocale,
            }"
            class="title-big"
            >OKI</router-link
          >
          <p class="color-grey">Office of Knowledge and Innovation</p>
        </div>

        <div v-else-if="active === 1" class="group-body__item">
          <a
            :href="t(`nav_qr_link_experience`)"
            target="_blank"
            rel="noopener noreferrer"
            class="title-big"
            >QRE</a
          >
          <p class="color-grey">QR Experience</p>
        </div>

        <div v-else-if="active === 2" class="group-body__item">
          <a
            :href="t(`nav_qr_link_cultural`)"
            target="_blank"
            rel="noopener noreferrer"
            class="title-big"
            >CCA</a
          >
          <p class="color-grey">Cultural Creative Agency</p>
        </div>

        <div v-else-if="active === 3" class="group-body__item">
          <a
            :href="t(`nav_qr_link_sports`)"
            target="_blank"
            rel="noopener noreferrer"
            class="title-big"
            >QRS</a
          >
          <p class="color-grey">QR Sport</p>
        </div>

        <div v-else-if="active === 4" class="group-body__item">
          <a
            :href="t(`nav_qr_link_investment`)"
            target="_blank"
            rel="noopener noreferrer"
            class="title-big"
            >QRITA</a
          >
          <p class="color-grey">QR Investment & Trade Advisory</p>
        </div>
      </transition>
      <div class="group-controls">
        <Button class="btn-grey" :msg="t(`common_prev`)" @click="prev" />
        <Button class="btn-grey" :msg="t(`common_next`)" @click="next" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

import Button from "@/components/Button";

export default defineComponent({
  name: "QRCCGroupMobile",
  components: {
    Button,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t, locale };
  },
  data() {
    return {
      active: 0,
      length: 4,
      toggle: false,
    };
  },
  methods: {
    prev() {
      this.toggle = !this.toggle;

      if (this.active === 0) {
        this.active = this.length;
        return;
      }
      this.active--;
    },
    next() {
      this.toggle = !this.toggle;

      if (this.active >= this.length) {
        this.active = 0;
        return;
      }
      this.active++;
    },
  },
});
</script>

<style scoped lang="scss">
.group {
  margin: 0 calc(#{valM(-24)} - 1px);
  width: 100vw;
  position: relative;
  background-color: #fff;
  > img {
    display: block;
  }
  &-body {
    position: relative;
    z-index: 10;
    padding: 0 valM(10 + 14);
    padding-top: valM(18);
    padding-bottom: valM(18);
    &__item {
      display: grid;
      grid-auto-rows: 1fr auto;
      // min-height: valM(0);
      margin-top: valM(18);
      margin-bottom: valM(36);
    }
    .small {
      font-size: valM(21);
    }
    .title-big {
      font-size: valM(60);
    }
  }
  &-controls {
    display: inline-grid;
    grid-auto-flow: column;
    justify-content: start;
    width: 100%;
    margin-top: valM(10);
    gap: valM(14);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<i18n>
{
  "en": {
    "QRCCGroupMobile1": "QRCC GROUP",
  },
  "ru": {
    "QRCCGroupMobile1": "Группа компаний QRCC",
  },
  "ar": {
    "QRCCGroupMobile1": "مجموعة مركز التعاون القطري الروسي ",
  }
}
</i18n>
