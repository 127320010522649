{
  "common_prev": "Назад",
  "common_next": "Вперед",
  "common_more": "Подробнее",
  "common_close": "Закрыть",
  "common_menu": "Меню",
  "common_email": "Ваш адрес",
  "common_send": "Отправить",
  "common_more_news": "Показать еще",
  "common_more_projects": "Больше проектов",
  "common_more_projects_hide": "Свернуть",
  "common_website": "Сайт",

  "nav_qr_link_investment": "https://qradvisory.ru/ru/main-rus/",
  "nav_qr_link_cultural": "https://ccagency.world/ru/",
  "nav_qr_link_experience": "https://qrexperience.ru/",
  "nav_qr_link_sports": "http://qrsports.world/ru/main-page-ru/",
  "nav_qr_link_media": "https://qrmedia.world/ru/",

  "nav_about": "О группе компаний QRCC",
  "nav_education": "Образование",
  "nav_edutainment": "Эдьютейнмент",
  "nav_qalc": "Катарский центр арабского языка (QALC)",
  "nav_innovation": "Инновации",
  "nav_social": "Социальная сфера",
  "nav_science": "Наука",
  "nav_news": "Новости",
  "nav_projects": "Наши проекты",
  "nav_culture": "Год культуры 2018",
  "nav_culture_link": "https://old.qatarrussia.ru",
  "nav_office": "Office of Knowledge<br />and Innovation",
  "nav_office2": "OKI",
  "nav_spief": "ПМЭФ 2021",
  "nav_spief_link": "https://spiefqatar.ru"
}