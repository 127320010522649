{
  "common_prev": "سابق",
  "common_next": "لاحق",
  "common_more": "اقرأ المزيد",
  "common_close": "اغلاق",
  "common_menu": "القائمة",
  "common_email": "عنوانك",
  "common_send": "الاشتراك",
  "common_more_news": "أخبار أخرى",
  "common_more_projects": "مشاريع أخرى",
  "common_more_projects_hide": "إخفاء المشاريع",
  "common_website": "الموقع",

  "nav_qr_link_investment": "https://qradvisory.ru/",
  "nav_qr_link_cultural": "https://ccagency.world/",
  "nav_qr_link_experience": "https://qrexperience.ru/en/",
  "nav_qr_link_sports": "http://qrsports.world/",
  "nav_qr_link_media": "https://qrmedia.world/",

  "nav_about": "عن مركز التعاون القطري الروسي",
  "nav_education": "التعليم",
  "nav_edutainment": "التعلم بالتسلية",
  "nav_qalc": "المركز القطري للغة العربية",
  "nav_innovation": "الابتكار",
  "nav_social": "المجال الاجتماعي",
  "nav_science": "العلم",
  "nav_news": "الأخبار",
  "nav_projects": "مشاريعنا ",
  "nav_culture": "السنة الثقافية 2018",
  "nav_culture_link": "https://old.qatarrussia.ru/en/",
  "nav_office": "مكتب المعرفة والابتكار ",
  "nav_office2": "مكتب المعرفة والابتكار ",
  "nav_spief": "منتدى بطرسبورغ الاقتصادي الدولي 2021",
  "nav_spief_link": "https://spiefqatar.com"
}