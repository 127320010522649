<template>
  <div class="grid-column grid-column_row_2">
    <div class="grid-inside">
      <div v-if="isMobile" class="pm-18"></div>
      <h3 class="h3 upper">{{ t("newstitle_1") }}</h3>
      <div v-if="isMobile" class="pm-54"></div>
      <Button
        v-if="!isMobile"
        class="btn-default-grey only-desktop"
        :to="{ name: 'News', params: paramsLocale }"
        :msg="t('newstext_1')"
      />
    </div>
  </div>
  <div
    v-for="(item, index) in normalizeNews"
    :key="index"
    class="grid-column grid-column_row_2"
  >
    <NewsCard
      v-if="item && item.preview"
      :id="item.id"
      :title="item.title"
      :date="item.date"
      :image="item.preview.path"
    />
    <div v-if="isMobile">
      <div
        v-if="normalizeNews.length - 1 !== index && item"
        class="grid-line"
      ></div>
      <div v-if="normalizeNews.length - 1 === index">
        <div v-if="isMobile" class="pm-18"></div>
        <Button
          class="btn-default-grey btn-block"
          :to="{ name: 'News', params: paramsLocale }"
          :msg="t('newstext_1')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { datetimeFormats } from "@/utils/formats";
import { mapState, mapActions } from "vuex";

import Button from "@/components/Button.vue";
import NewsCard from "@/components/NewsCard.vue";

export default defineComponent({
  name: "News",
  components: {
    Button,
    NewsCard,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      datetimeFormats,
    });

    return { t, locale };
  },
  computed: {
    ...mapState(["news", "newsStatus"]),
    normalizeNews() {
      const length = 4;
      const array = [];

      for (let index = 0; index < length; index++) {
        if (this.news && this.news.length) {
          array[index] = this.news[index];
        } else {
          array[index] = null;
        }
      }

      return array;
    },
  },
  watch: {
    locale() {
      this.fetchData(true);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["fetchNews"]),
    fetchData(reload) {
      if (reload || this.newsStatus !== "success") {
        this.fetchNews();
      }
    },
    // fetchDataRu() {
    //   this.news[0] = {
    //     title:
    //       "Генеральный директор QRCC Диана Чармадова в интервью «Российской газете»",
    //     link: "/news/123",
    //     date: "2020-06-30T08:35:10.802925+00:00",
    //     image: "/static/pic/demo/4.png",
    //   };
    //   this.news[1] = {
    //     title:
    //       "Онлайн-дискуссия Doha Forum и Института международных и стратегических исследований Пекинского университета.",
    //     link: "/news/123",
    //     date: "2020-06-30T08:35:10.802925+00:00",
    //     image: "/static/pic/demo/5.png",
    //   };
    //   this.news[2] = {
    //     title:
    //       "Объявлены итоги конкурса по арабскому языку, организованного Катарским центром арабского языка совместно с QatarDebate Center",
    //     link: "/news/123",
    //     date: "2020-06-30T08:35:10.802925+00:00",
    //     image: "/static/pic/demo/6.png",
    //   };
    //   this.news[3] = {
    //     title:
    //       "Объявлен набор волонтеров Культурной программы государства Катар в рамках ПМЭФ’2021",
    //     link: "/news/123",
    //     date: "2020-06-30T08:35:10.802925+00:00",
    //     image: "/static/pic/demo/3.png",
    //   };
    // },
  },
});
</script>

<style scoped lang="scss"></style>

<i18n>
{
  "en": {
    "newstitle_1": "News",
    "newstext_1": "All news",
  },
  "ru": {
    "newstitle_1": "Новости",
    "newstext_1": "Все новости",
  },
  "ar": {
    "newstitle_1": "الأخبار",
    "newstext_1": "كامل الأخبار",
  }
}
</i18n>
