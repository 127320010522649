<template>
  <div class="grid-column">
    <div v-if="isMobile" class="pm-18"></div>
    <h2 class="h2">{{ t("t1") }}</h2>
  </div>
  <div class="grid-column">
    <div class="content-stretch">
      <div v-if="isMobile" class="pm-76"></div>
      <h4 class="h4" v-html="t('t2')"></h4>
      <div v-if="isMobile" class="pm-18"></div>
      <div class="content-stretch__cell">
        <Button
          :to="{
            name: 'About',
            params: paramsLocale,
          }"
          :msg="t('common_more')"
        />
      </div>
    </div>
  </div>
  <div class="grid-column">
    <!-- <Promo
      class="promo_full_width"
      :src-pic="require(`@/assets/promo/1.jpg`)"
      :src-pic-mobile="require(`@/assets/promo/1m.jpg`)"
    /> -->
    <Promo
      class="promo_full_width"
      :src-pic="image.path"
      :src-pic-mobile="image.mobile_path"
      :src-video="video.path"
      :src-video-mobile="video.mobile_path"
      :is-video="isVideoShow"
    />
  </div>
  <div class="grid-column">
    <h2
      class="upper"
      :class="{ h3: isMobile, h2: !isMobile }"
      v-html="t(`t7`)"
    ></h2>
    <div v-if="isMobile" class="pm-76"></div>
  </div>
  <div class="grid-column"></div>
  <div class="grid-column">
    <div class="content-pattern content-pattern_type_1"></div>
  </div>
  <div class="grid-column grid-column_row_3"></div>
  <div class="grid-column grid-column_row_3">
    <Gallery :pics="gallery" />
    <div v-if="isMobile" class="pm-24"></div>
  </div>
  <div class="grid-column">
    <div class="content-pattern content-pattern_type_1"></div>
  </div>
  <div class="grid-column">
    <div class="content-pattern content-pattern_type_2"></div>
  </div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column">
    <div v-if="isMobile" class="grid-line"></div>
    <h3 class="h3 upper">{{ t(`t8`) }}</h3>
  </div>
  <div class="grid-column">
    <div v-if="isMobile" class="pm-54"></div>
    <div class="content-list">
      <div class="h3">01</div>
      <br />
      <div class="h4" v-html="t('t3')"></div>
    </div>
    <div v-if="isMobile" class="grid-line"></div>
  </div>
  <div class="grid-column">
    <div v-if="isMobile" class="pm-18"></div>
    <div class="content-list">
      <div class="h3">02</div>
      <br />
      <div class="h4" v-html="t('t4')"></div>
    </div>
    <div v-if="isMobile" class="grid-line"></div>
  </div>
  <div class="grid-column">
    <div v-if="isMobile" class="pm-18"></div>
    <div class="content-list">
      <div class="h3">03</div>
      <br />
      <div class="h4" v-html="t('t5')"></div>
    </div>
    <div v-if="isMobile" class="grid-line"></div>
  </div>
  <div class="grid-column">
    <div class="content-list">
      <div class="h3">04</div>
      <br />
      <div class="h4" v-html="t('t6')"></div>
    </div>
    <div v-if="isMobile" class="pm-42"></div>
  </div>
  <QRCCGroupMobile v-if="isMobile" />
  <QRCCGroup v-else />
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <News />
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

import { GetMain } from "@/api/Pages";

import Promo from "@/components/Promo.vue";
import Button from "@/components/Button.vue";
import Gallery from "@/components/Gallery.vue";
import News from "@/components/News.vue";
import QRCCGroup from "@/components/QRCCGroup.vue";
import QRCCGroupMobile from "@/components/QRCCGroupMobile.vue";

export default defineComponent({
  name: "Home",
  components: {
    Promo,
    Button,
    Gallery,
    News,
    QRCCGroup,
    QRCCGroupMobile,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t, locale };
  },
  data() {
    return {
      video: {},
      image: {},
      isVideoShow: false,
      gallery: [],
    };
  },
  watch: {
    locale() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const resp = await GetMain();
        const {
          gallery = [],
          image = {},
          show_video = false,
          video = {},
        } = resp.data.data;

        this.isVideoShow = show_video;
        this.gallery = gallery.images;
        this.video = video;
        this.image = image;
        this.$progress.finish();
      } catch (error) {
        this.$progress.finish();
        console.log(error);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@include mobile {
  .grid-column {
  }
}
@include desktop {
  .grid-column {
    &:nth-of-type(6) {
      grid-column: span 2;
    }
    &:nth-of-type(7) {
      grid-column: span 3;
    }
    &:nth-of-type(8) {
      grid-column: span 5;
    }
    &:nth-of-type(9) {
      grid-column: span 3;
    }
    &:nth-of-type(13) {
      grid-column: span 4;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "t1": "QATARI-RUSSIAN CENTER FOR COOPERATION",
    "t2": "Promoting multi-faceted and cross-sectoral bilateral relations, the Qatari-Russian Center for Cooperation works to sustainably grow the bilateral landscape between the two nations.",
    "t3": "Bilateral relationship and partnership vehicle",
    "t4": "Trusted advisor to government and private business entities",
    "t5": "Knowledge and expertise across multiple categories",
    "t6": "Dedicated to Qatar-Russia bilateral engagement promotion",
    "t7": "PROMOTING MULTI-FACED AND<br />CROSS-SECTORAL BILATERAL<br />RELATIONS",
    "t8": "FEATURES",
  },
  "ru": {
    "t1": "QATARI-RUSSIAN CENTER FOR COOPERATION",
    "t2": "Катарско-российский центр сотрудничества (QRCC) способствует устойчивому развитию отношений между двумя странами по различным направлениям двустороннего сотрудничества.",
    "t3": "Эффективный инструмент развития двусторонних отношений и партнерства",
    "t4": "Надежный консультант для государственных организаций и частных компаний",
    "t5": "Опытная команда, обладающая глубокими знаниями в самом широком кругу вопросов",
    "t6": "Наша цель – содействие двустороннему сотрудничеству между Катаром и Россией",
    "t7": "Способствуем устойчивому развитию отношений между двумя странами по различным направлениям двустороннего сотрудничества",
    "t8": "Преимущества",
  },
  "ar": {
    "t1": "مركز التعاون القطري الروسي ",
    "t2": "يساهم المركز القطري الروسي للتعاون في التنمية المستدامة للعلاقات بين البلدين في مختلف مجالات التعاون الثنائي.",
    "t3": "أداة فعالة لتطوير العلاقات والشراكات الثنائية",
    "t4": "مستشار يمكن الوثوق به بالنسبة للمؤسسات الحكومية والشركات الخاصة",
    "t5": "فريق خبراء يتمتع بزاد معرفي عميق في اختصاصاته  المتعددة",
    "t6": "هدفنا هو تعزيز التعاون الثنائي بين قطر وروسيا",
    "t7": "نساهم في التنمية المستدامة للعلاقات بين البلدين في مختلف مجالات التعاون الثنائي",
    "t8": "المزايا",
  }
}
</i18n>
