<template>
  <a
    v-if="target === `_blank`"
    :href="to"
    target="_blank"
    rel="noopener noreferrer"
    class="btn"
    >{{ msg }}</a
  >
  <router-link v-else-if="to" class="btn" :to="to">{{ msg }}</router-link>
  <button v-else class="btn" :to="to">{{ msg }}</button>
</template>

<script>
export default {
  name: "Button",
  props: {
    to: [String, Object],
    msg: String,
    target: String,
  },
};
</script>

<style scoped lang="scss">
$primary-color-white: #fff;
$primary-color-black: #000;
$primary-color-grey: #f3efeb;

.btn {
  @include reset-button;
  display: inline-grid;
  place-items: center;
  padding: 0 val(15);
  height: val(34);
  background: $primary-color-white;
  border-radius: 100px;
  border: 1px solid $primary-color-white;
  line-height: 1;
  text-decoration: none;
  color: $primary-color-black;
  transition: 0.1s;
  transition: 0.1s;
  &:hover {
    border-color: darken($primary-color-white, 3%);
    background-color: darken($primary-color-white, 3%);
  }
  &:active {
    border-color: darken($primary-color-white, 6%);
    background-color: darken($primary-color-white, 6%);
  }
  &[disabled] {
    cursor: default;
    border-color: darken($primary-color-white, 6%);
    background-color: darken($primary-color-white, 6%);
  }
  @include mobile {
    padding: 0 valM(15);
    height: valM(32);
  }
  &-default {
    background: none !important;
    color: $primary-color-white;
    &-grey {
      background: none !important;
      border-color: $primary-color-black;
      color: $primary-color-black;
      &:hover {
        border-color: lighten($primary-color-black, 40%);
      }
      &:active {
        border-color: lighten($primary-color-black, 60%);
      }
    }
  }
  &-grey {
    border: none !important;
    background: $primary-color-grey;
    &:hover {
      background-color: darken($primary-color-grey, 3%);
    }
    &:active {
      background-color: darken($primary-color-grey, 6%);
    }
  }
  &-block {
    width: 100%;
  }
}
</style>
