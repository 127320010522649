{
  "common_prev": "Prev",
  "common_next": "Next",
  "common_more": "Read more",
  "common_close": "Close",
  "common_menu": "Menu",
  "common_email": "Your email address",
  "common_send": "Send",
  "common_more_news": "More news",
  "common_more_projects": "More projects",
  "common_more_projects_hide": "Hide projects",
  "common_website": "Website",

  "nav_qr_link_investment": "https://qradvisory.ru/",
  "nav_qr_link_cultural": "https://ccagency.world/",
  "nav_qr_link_experience": "https://qrexperience.ru/en/",
  "nav_qr_link_sports": "http://qrsports.world/",
  "nav_qr_link_media": "https://qrmedia.world/",

  "nav_about": "About QRCC Group",
  "nav_education": "Education",
  "nav_edutainment": "Edutainment",
  "nav_qalc": "Qatar Arabic language center",
  "nav_innovation": "Innovation",
  "nav_social": "Social",
  "nav_science": "Science",
  "nav_news": "News",
  "nav_projects": "Our projects",
  "nav_culture": "Year of Culture 2018",
  "nav_culture_link": "https://old.qatarrussia.ru/en/",
  "nav_office": "Office of Knowledge<br />and Innovation",
  "nav_office2": "OKI",
  "nav_spief": "SPIEF 2021",
  "nav_spief_link": "https://spiefqatar.com"
}