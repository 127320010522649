import axios from "axios";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
});

service.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    config.headers.common["Accept-Language"] =
      document.documentElement.getAttribute("lang") ||
      process.env.VUE_APP_I18N_LOCALE;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export default service;
